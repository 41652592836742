export default function MessageReceive({ message, openFile }) {
    return (
        <div className="row msg_container base_receive">
            <div className="col-md-2 col-xs-2 avatar">
                <img src="https://img.icons8.com/color/36/000000/administrator-male.png" className="img-responsive " />
            </div>
            <div className="col-md-10 col-xs-10">
                <div className="messages msg_receive">
                    <p>
                        {message.body}
                        {message.fileType && (
                            <button onClick={() => openFile(message.filePath)}
                                className="btn btn-success btn-sm">
                                <i className="fa fa-download"></i>
                                &emsp;
                                Abrir anexo
                            </button>
                        )}
                    </p>
                    <time>Suporte • {message.time.slice(0, -3)}</time>
                </div>
            </div>
        </div>
    )
}