

import { useState } from 'react';
import Chat from './components/chat/Chat.js';
import logoIcon from './logo-icon.png';
import logo from './logo.png';

function App() {
  const [chatIsOpen, setChatIsOpen] = useState(true);

  function handleOpenChat() {
    setChatIsOpen(true);
  }

  function handleCloseChat() {
    setChatIsOpen(false);
  }

  return (
    <>
      <div className="navbar navbar-inverse navbar-fixed-top">
        <div className="container">
          <div className="navbar-header">
            <button type="button" className="navbar-toggle" data-toggle="collapse" data-target=".navbar-collapse">
              <span className="icon-bar"></span>
              <span className="icon-bar"></span>
              <span className="icon-bar"></span>
            </button>
            <a className="navbar-brand" href="#"><img className="logo-icon" src={logoIcon} /></a>
          </div>
          <div className="navbar-collapse collapse">
            <ul className="nav navbar-nav navbar-right">
              <li><a href="#home-sec">Inicio</a></li>
              <li><a href="#services-sec">Serviços</a></li>
              <li><a href="#contato-sec">Contato</a></li>
              <li><a href="https://www.facebook.com/pages/Conectiva-Solucoes-Em-Inform%C3%A1tica/602724239908464"
                target="_blank">Facebook</a></li>
            </ul>
          </div>
        </div>
      </div>
      <div id="home-sec">
        <div className="container" id="home">
          <div className="row text-center g-pad-bottom">
            <div className="col-md-12">
              <span className="head-main">
                <img src={logo} className='img-responsive col-xs-12' />
              </span>
            </div>
          </div>
          <div className="row text-center">
            <div className="col-md-4  col-sm-4">
              <i className="fa fa-headphones faa-vertical animated icon-round bk-color-red"></i>
              <h4>Suporte técnico</h4>
              <p>
                Help Desk online, comece um atendimento agora mesmo <span onClick={handleOpenChat}>clicando aqui <i className="fa fa-arrow-down"></i></span>.
              </p>
            </div>
            <div className="col-md-4  col-sm-4">
              <i className="fa fa-desktop  faa-pulse animated icon-round bk-color-blue"></i>
              <h4>Sistemas</h4>
              <p>
                Soluções para vendas, gestão hospitalar e aplicativos para dispositivos móveis.
              </p>
            </div>
            <div className="col-md-4  col-sm-4">
              <i className="fa fa-gear faa-horizontal animated icon-round bk-color-black"></i>
              <h4>Equipamentos</h4>
              <p>
                Mnutenção e venda de computadores, notebooks, monitores,
                impressoras e mais.
              </p>
            </div>
          </div>
        </div>
      </div>
      <section id="services-sec">
        <div className="container">
          <div className="row pad-top">
            <div className="col-md-12 text-center">
              <div className="col-md-4 col-sm-4">
                <div className="panel panel-primary">
                  <div className="panel-heading">
                    Acesso remoto
                  </div>
                  <div className="panel-body">
                    <p>Baixe o aplicativo de acesso remoto AnyDesk
                      e ligue para (62) 3091-4380 utlizar
                      o Suporte Online.</p>
                  </div>
                  <div className="panel-footer">
                    <a href="#price-sec">Solicite ajuda</a>
                  </div>
                </div>
              </div>
              <div className="col-md-4 col-sm-4">
                <div className="panel panel-danger">
                  <div className="panel-heading">
                    Automação comercial
                  </div>
                  <div className="panel-body">
                    <p>Aplicações com estabilidade e segurança, oferecendo soluções para
                      empresas de pequeno, médio e grande porte.</p>
                  </div>
                  <div className="panel-footer">
                    <a href="#price-sec">Fale conosco</a>
                  </div>
                </div>
              </div>
              <div className="col-md-4 col-sm-4">
                <div className="panel panel-info">
                  <div className="panel-heading">
                    Suporte
                  </div>
                  <div className="panel-body">
                    <p>Se está enfrentando problemas técnicos na sua internet, computador, impressora e
                      precisa um suporte, fale com a gente.</p>
                  </div>
                  <div className="panel-footer">
                    <a href="#price-sec">Agende uma visita</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="c-sts" id="sts-img">
        <div className="container">
          <div className="row ">
            <div className="col-md-9 ">
              <div className="stats-div">
                <h3>Alguns de nossos produtos </h3>
                <h4>Descubra um pouco sobre oque podemos fazer por você..</h4>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section id="price-sec">
        <div className="container">
          <div className="row ">
            <div className="col-md-12 text-center ">
              <div className="col-md-4 col-sm-4">
                <ul className="plan">
                  <li className="plan-head btn-danger">Publicidade</li>
                  <li><strong>+</strong> Facebook</li>
                  <li><strong>+</strong> Instagram</li>
                  <li><strong>+</strong> Google Ads</li>
                  <li><strong>+</strong> Campanhas de e-mails</li>
                  <li className="main-price">Á partir de R$100,00</li>
                  <li className="bottom">
                    <a href="#contato-sec" className="btn btn-danger btn-lg ">Contate-nos</a>
                  </li>
                </ul>
              </div>
              <div className="col-md-4  col-sm-4">
                <ul className="plan">
                  <li className="plan-head btn-primary">Sistema</li>
                  <li><strong>+</strong> Controle de estoque</li>
                  <li><strong>+</strong> Vendas com NF-e</li>
                  <li><strong>+</strong> Segurança</li>
                  <li><strong>+</strong> Velocidade</li>
                  <li className="main-price">Á partir de R$150,00</li>
                  <li className="bottom">
                    <a href="#contato-sec" className="btn btn-primary btn-lg ">Contate-nos</a>
                  </li>
                </ul>
              </div>
              <div className="col-md-4  col-sm-4">
                <ul className="plan">
                  <li className="plan-head btn-success">Aplicativo</li>
                  <li><strong>+</strong> Android</li>
                  <li><strong>+</strong> IOS</li>
                  <li><strong>+</strong> Praticidade</li>
                  <li><strong>+</strong> Sua empresa na mão</li>
                  <li className="main-price">Á partir de R$500,00</li>
                  <li className="bottom">
                    <a href="#contato-sec" className="btn btn-success btn-lg">Contate-nos</a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="c-sts" id="sts-img">
        <div className="container">
          <div className="row ">
            <div className="col-md-9 ">
              <div className="stats-div">
                <h3>Contate-nos agora </h3>
                <h4>E faça um Upgrade na sua empresa..</h4>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section id="contato-sec">
        <div className="container">
          <div className="row ">
            <div className="col-md-12 text-center ">
              <div className="col-md-6 col-sm-6">
                <ul className="plan">
                  <li className="plan-head btn-danger">Andre</li>
                  <li><strong>Telefone: </strong>(62) 98407-2261</li>
                  <li><strong>Email: </strong>andre@conectivainformatica.com.br</li>
                </ul>
              </div>
              <div className="col-md-6  col-sm-6">
                <ul className="plan">
                  <li className="plan-head btn-primary">Luiz</li>
                  <li><strong>Telefone: </strong>(62) 98619-7672</li>
                  <li><strong>Email: </strong>luiz@conectivainformatica.com.br</li>
                </ul>
              </div>
              <div className="col-md-6  col-sm-6">
                <ul className="plan">
                  <li className="plan-head btn-success">Lucas</li>
                  <li><strong>Telefone: </strong>(62) 98274-0530</li>
                  <li><strong>Email: </strong>lucas@conectivainformatica.com.br</li>
                </ul>
              </div>
              <div className="col-md-6  col-sm-6">
                <ul className="plan">
                  <li className="plan-head btn-warning">Christopher</li>
                  <li><strong>Telefone: </strong>(62) 983077539</li>
                  <li><strong>Email: </strong>christopher@conectivainformatica.com.br</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
      <div className="add">
        <i>Endereço: </i> Rua C-209 Qd. 526 Lt. 09/11 Sl. 06 - Jardim América - CEP 74270-220, Goiânia - GO
        <i>Telefone: </i> 62 9 8148-5673
      </div>
      <iframe className="cnt"
        src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d15285.538973692963!2d-49.28438!3d-16.707644!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x5340bd3d46be9341!2sConectiva+Inform%C3%A1tica!5e0!3m2!1spt-BR!2sus!4v1471824120134"></iframe>
      <div id="footer">
        2019 Conectiva Informática | Todos direitos reservados
      </div>
      <Chat onClose={handleCloseChat} isOpen={chatIsOpen} />
      {!chatIsOpen && (
        <a href="#" className="chat-button" onClick={handleOpenChat}>
          <i className="fa fa-comment chat-button-icon"></i>
        </a>
      )}
    </>
  );
}

export default App;
