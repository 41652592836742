import { useEffect, useRef, useState } from 'react';
import axios from "axios";
import Pusher from 'pusher-js';

import './Chat.css';
import MessageReceive from './MessageReceive';
import MessageSent from './MessageSent';
import { API_CHANEL, API_URL, PUSHER_APP_KEY } from '../../constants';

function generateId() {
    return (Math.random() + 1).toString(36).substring(7);
}

const clientIdentifier = generateId();

export default function Chat({ onClose, isOpen }) {
    const [message, setMessage] = useState('');
    const [messages, setMessages] = useState([]);
    const [clientName, setClientName] = useState('');
    const [chatStarted, setChatStarted] = useState(false);

    const messagesEndRef = useRef(null);
    const fileToAttach = useRef(null);

    useEffect(() => {
        const pusher = new Pusher(PUSHER_APP_KEY, {
            cluster: 'sa1'
        });

        const pusherChannel = pusher.subscribe('clientFrom-conversation-channel');

        pusherChannel.bind(clientIdentifier, data => {
            setMessages(prevMessages => [...prevMessages, {
                ...data.message,
                time: new Date().toLocaleTimeString(),
                id: generateId()
            }]);

            scrollToBottom();
        });
    }, []);

    function handleStartChat() {
        setChatStarted(true);
    }

    function scrollToBottom() {
        setTimeout(() => messagesEndRef.current?.scrollIntoView(), 100);
    }

    async function handleSubmitMessage(event) {
        event.preventDefault();
        if (message.trim() !== "") {
            const messageId = generateId();

            setMessages(prevMessages => [...prevMessages, {
                isFromClient: true,
                body: message,
                id: messageId,
                time: new Date().toLocaleTimeString()
            }]);

            setMessage('');

            scrollToBottom();

            try {
                await axios.post(API_URL + '/api/chanels/' + API_CHANEL + '/message-from-client', {
                    body: message,
                    pushname: clientName,
                    username: clientIdentifier
                });
            } catch (error) {
                setMessages(prevMessages => prevMessages.filter(message => message.id !== messageId));
                alert('Houve um erro, tente novamente mais tarde.');
            }
        }
    }

    function handleMessageChange(event) {
        setMessage(event.target.value);
    }

    function handleClientNameChange(event) {
        setClientName(event.target.value);
    }

    function handleSelectFile() {
        fileToAttach.current?.click();
    }

    async function handleSendFile(event) {
        const file = event.target.files[0];
        const messageId = generateId();

        try {
            const formData = new FormData();
            formData.append('file', file);
            formData.append('fileType', file.type);
            formData.append('pushname', clientName);
            formData.append('username', clientIdentifier);

            const response = await axios.post(API_URL + '/api/chanels/' + API_CHANEL + '/message-from-client', formData);

            const messageCreated = response.data;

            setMessages(prevMessages => [...prevMessages, {
                ...messageCreated,
                time: new Date().toLocaleTimeString()
            }]);

            scrollToBottom();
        } catch (error) {
            setMessages(prevMessages => prevMessages.filter(message => message.id !== messageId));
            alert('Houve um erro, tente novamente mais tarde.');
        }
    }

    function handleOpenFile(filePath) {
        window.open(API_URL + '/storage/' + filePath, '_blank').focus();
    }

    return (
        <div className={['col-xs-12 col-md-4 ', isOpen && ' chat-window']}>
            <div className="col-xs-12 col-md-12">
                <div className="panel panel-default">
                    <div className="panel-heading top-bar">
                        <div className="col-md-8 col-xs-8">
                            <h3 className="panel-title"><span className="glyphicon glyphicon-comment"></span> Chat - Suporte</h3>
                        </div>
                        <div className="col-md-4 col-xs-4 text-right">
                            <a href="#" onClick={onClose}><span className="glyphicon glyphicon-remove"></span></a>
                        </div>
                    </div>
                    {chatStarted ? (
                        <>
                            <div className="panel-body msg_container_base" id="msg_container_base">
                                {messages.map(message => message.isFromClient ? (
                                    <MessageSent key={message.id} message={message} clientName={clientName} openFile={handleOpenFile} />
                                ) : (
                                    <MessageReceive message={message} key={message.id} openFile={handleOpenFile} />)
                                )}
                                <div ref={messagesEndRef}></div>
                            </div>
                            <div className="panel-footer">
                                <form onSubmit={handleSubmitMessage}>
                                    <div className="input-group">
                                        <span className="input-group-btn">
                                            <input type="file" className="hidden" ref={fileToAttach} onChange={handleSendFile} />
                                            <button className="btn btn-sm btn-info" type="button" onClick={handleSelectFile}>
                                                <i className="fa fa-file"></i>
                                            </button>
                                        </span>
                                        <input onChange={handleMessageChange} ref={input => input && input.focus()}
                                            value={message} type="text" className="form-control input-sm chat_input" placeholder="Digite sua mensagem..." />
                                        <span className="input-group-btn">
                                            <button className="btn btn-primary btn-sm">Enviar</button>
                                        </span>
                                    </div>
                                </form>
                            </div>
                        </>
                    ) : (
                        <>
                            <div className="panel-body">
                                <input className="form-control" placeholder='Seu nome' onChange={handleClientNameChange} value={clientName} ref={input => input && input.focus()} />
                            </div>
                            <div className="panel-footer">
                                <button className="btn btn-primary btn-block" onClick={handleStartChat}>Começar</button>
                            </div>
                        </>
                    )}
                </div>
            </div>
        </div>
    );
}